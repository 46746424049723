<template>
	<div class="main">
		<div class="welcome">
			<Form inline>
        <FormItem>
          <Input v-model="search.secret_id" placeholder="请输入SecretId" style="width: 320px;" />
        </FormItem>
        <FormItem>
          <Input v-model="search.name" placeholder="请输入应用名称" style="width: 320px;" />
        </FormItem>
        <FormItem>
          <Button icon="ios-search" type="primary" @click="pageChange(1)">搜索</Button>
        </FormItem>
      </Form>
		</div>
<!--		<div class="show-data">-->
<!--			<div class="data-item">-->
<!--				<div class="item-img"><i class="iconfont icon-zhandian"></i></div>-->
<!--				<div class="text"><span class="label">站点数&nbsp;&nbsp;</span><span class="amount"-->
<!--						style="color: #53e0f7">{{ sum.site }}</span>-->
<!--				</div>-->
<!--			</div>-->
<!--			<div class="data-item">-->
<!--				<div class="item-img" style="background-color: #edcc29;"><i class="iconfont icon-duanxin"></i></div>-->
<!--				<div class="text"><span class="label">短信数&nbsp;&nbsp;</span><span class="amount"-->
<!--						style="color: #edcc29;">{{ sum.message }}</span>-->
<!--				</div>-->
<!--			</div>-->
<!--			&lt;!&ndash; <div class="data-item">-->
<!--        <div class="item-img" style="background-color:#d81e06"><i class="iconfont icon-wuguan"></i></div>-->
<!--        <div class="text"><span class="label">AI数&nbsp;&nbsp;</span><span class="amount"-->
<!--            style="color:#d81e06">{{sum.ai}}</span>-->
<!--        </div>-->
<!--      </div> &ndash;&gt;-->
<!--			<div class="data-item">-->
<!--				<div class="item-img" style="background-color: #00a2ff"><i class="iconfont icon-yingjian"></i></div>-->
<!--				<div class="text"><span class="label">硬件数&nbsp;&nbsp;</span><span class="amount"-->
<!--						style="color:#00a2ff">{{ sum.hardware }}</span>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
		<div class="my-site" style="position: relative;">
      <Spin fix v-if="loading"></Spin>
      <Button icon="ios-add" type="primary" @click="addModal.show = true">添加应用</Button>
      <Row :gutter="36" style="margin-top: 24px;" :wrap="true">
        <Col span="8" v-for="(item, index) in data" :key="index">
          <div class="apply-item">
            <div class="item-tag">已上线</div>
            <div class="item-img">
              <img :src="item.thumb" alt="暂无图片" />
            </div>
            <div class="item-info">
              <div class="info-title">{{ item.name }}</div>
              <div class="info-desc">SecretId: {{ item.secret_id }}</div>
              <div>绑定设备: {{ item.device }}</div>
              <div>设备库量: {{ item.remaining }}/台 <span style="color: #42A4FF; cursor: pointer;" @click="openDeviceModal(item)">修改套餐</span></div>
            </div>
            <Button class="item-btn" type="primary" @click="toUrl('/apply?secretId='+item.secret_id)">应用管理</Button>
          </div>
        </Col>
      </Row>
      <Page class-name="page-r" :total="search.total" :current="search.page"
            :page-size="search.pageSize" :page-size-opts="[9,18,36]" show-total show-sizer
            @on-change="pageChange" @on-page-size-change="pageSizeChange"></Page>
<!--			<div class="title">我的站点</div>-->
<!--			<div class="site-cont">-->
<!--				<div class="site-item" v-for="(item,index) in siteItem" :key="index">-->
<!--					<div v-if="item.is_sass" class="is_sass">{{ item.auth_site == 'adm.sz90.cn' ? 'sass' : '私有化' }}</div>-->
<!--					&lt;!&ndash; <div class="current_version">{{ item.current_version }}</div> &ndash;&gt;-->
<!--					<div class="site-img"><img :src="item.icon.indexOf('http') != -1 ? item.icon : (item.icon.indexOf('/uploads') != -1 ? item.auth_site + item.icon : 'https://' + item.icon)" alt=""></div>-->
<!--					<div class="site-text">-->
<!--						<div>{{ item.name }}</div>-->
<!--						<div>{{ item.auth_site }}</div>-->
<!--					</div>-->
<!--					<a style="color: #fff" target="_blank" :href="'https://'+item.auth_site">-->
<!--						<div class="action">-->
<!--							<p>管</p>-->
<!--							<p>理</p>-->
<!--						</div>-->
<!--					</a>-->
<!--				</div>-->
<!--			</div>-->
		</div>
    <Modal v-model="addModal.show" title="添加应用" width="800" @on-cancel="cancelModal" class-name="vertical-center-modal">
      <Form ref="addModalForm" :model="addModal.data" label-colon label-position="top">
        <FormItem label="应用名称" required>
          <Input v-model="addModal.data.name" placeholder="请输入应用名称"></Input>
        </FormItem>
        <FormItem label="图片">
          <div style="display: flex">
            <div v-if="addModal.data.thumb" style="width: 75px; height: 75px; margin-right: 12px;">
              <img :src="addModal.data.thumb" alt="" style="width: 100%; height: 100%;" />
            </div>
            <Uploads :action="ApiUrl+'/adm/upload_img'" :data="{dir:'img_temp',type:'base64'}" style="display: inline-block;" @upload_succ="uploadSuccess" />
          </div>

        </FormItem>
        <FormItem label="回调地址" required>
          <div style="display: flex">
            <Input v-model="addModal.data.dev_forward_url" placeholder="请输入回调地址">
              <template #prepend>
                <Select v-model="addModal.data.forwardStart" style="width: 80px">
                  <Option value="http">http://</Option>
                  <Option value="https">https://</Option>
                </Select>
              </template>
            </Input>
<!--            <Button type="primary" style="margin-left: 8px;">校验</Button>-->
          </div>
        </FormItem>
        <FormItem label="IP白名单">
          <Input v-model="addModal.data.ip_white_list" type="textarea" :autosize="{minRows: 5}" placeholder="请输入IP白名单"></Input>
        </FormItem>
      </Form>
      <template #footer>
        <Button @click="cancelModal">取消</Button>
        <Button type="primary" @click="confirmModal">确定</Button>
      </template>
    </Modal>
    <Modal v-model="deviceModal.show" title="添加库量" class-name="vertical-center-modal" @on-cancel="cancelDeviceModal">
      <Form :label-width="100" label-colon>
        <FormItem label="充值方式">
          <RadioGroup v-model="deviceModal.data.payType">
            <Radio :label="1" border>
<!--              <Icon custom="iconfont icon-weixinzhifu" color="#00c250"></Icon>-->
              <span>微信支付</span>
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="添加数量">
          <InputNumber
              style="width: 100%;"
              v-model="deviceModal.data.number"
              :max="99999" :min="1"
              :formatter="value => `${value} / 台`"
              :parser="value => value.replace(' / 台', '')" />
          <div style="margin-top: 8px;">设备流量套餐：{{ deviceModal.money }}元/台</div>
          <div>需支付：<span style="color: #ed4014;">￥ {{ deviceModalPrice }}</span></div>
        </FormItem>
      </Form>
      <template #footer>
        <Button @click="cancelDeviceModal">取消</Button>
        <Button type="primary" @click="confirmDeviceModal">确定</Button>
      </template>
    </Modal>
    <Modal v-model="payModal.show" @on-cancel="cancelPayModal()" :mask-closable="false" footer-hide class-name="vertical-center-modal" width="500">
      <template #header>
        <div style="text-align: center; width: 100%; font-weight: bold; font-size: 22px;">微信支付</div>
      </template>
      <vue-qr :text="payModal.code_url" :size="468" />
    </Modal>
	</div>
</template>

<script>
import Uploads from '@/components/common/Uploads'
import vueQr from 'vue-qr'
	export default {
		name: 'Home',
    components: {
      Uploads,
      vueQr
    },
		data() {
			return {
        ApiUrl: this.ApiUrl,
				user: JSON.parse(localStorage.getItem('userInfo')),
				datetime: null,
				siteItem: [],
				sum: {
					site: 0,
					message: 0,
					ai: 0,
					hardware: 0
				},
        search: {
          pageSize: 9,
          page: 1,
          total: 0,
          name: '',
          secret_id: ''
        },
        loading: false,
        data: [],
        addModal: {
          show: false,
          data: {
            name: '',
            thumb: '',
            dev_forward_url: '',
            forwardStart: 'https',
            ip_white_list: ''
          }
        },
        deviceModal: {
          show: false,
          money: 0.01,
          item: {},
          data: {
            payType: 1,
            number: 1
          }
        },
        payModal: {
          show: false,
          code_url: '',
          orderInterval: null
        }
			}
		},
    computed:{
      deviceModalPrice(){
        return this.deviceModal.data.number * this.deviceModal.money
      }
    },
		mounted() {
			var _this = this;
			setInterval(function() {
				_this.datetime = _this.getDateTime()
			}, 1000)
		},
		created() {
			if (this.$store.state.userInfo.uid) {
				this.init()
			}
		},
		methods: {
			init() {
				const _this = this
        this.loading = true
				this.requestApi('/adm/get_index', this.search).then((res) => {
          _this.loading = false
          if (res.status === 1) {
            _this.data = res.data.data
            _this.search.total = res.data.total
          } else {
            _this.alertErr(res.msg)
          }
        })
			},
      // 页码改变事件
      pageChange(page) {
        this.search.page = page;
        this.init()
      },
      // 每页条数改变
      pageSizeChange(size) {
        this.search.page = 1;
        this.search.pageSize = size;
        this.init()
      },
			getDateTime() {
				var date = new Date();
				var y = date.getFullYear(),
					m = date.getMonth() + 1,
					d = date.getDate(),
					h = this.sendDate(date.getHours()),
					i = this.sendDate(date.getMinutes()),
					s = this.sendDate(date.getSeconds());
				date = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;
				return date;
			},
			sendDate(d) {
				if (parseInt(d) < 10) {
					d = '0' + d;
				}
				return d;
			},
      cancelModal() {
        this.addModal.show = false
        // this.$refs.addModalForm.resetFields()
        this.addModal.data = {
          name: '',
          thumb: '',
          dev_forward_url: '',
          forwardStart: 'https',
          ip_white_list: ''
        }
      },
      confirmModal() {
        const _this = this

        if (!this.addModal.data.name) {
          return this.alertErr('请输入应用名称！')
        }

        if (!this.addModal.data.dev_forward_url) {
          return this.alertErr('请输入回调地址！')
        }

        let data = JSON.parse(JSON.stringify(this.addModal.data))
        data.dev_forward_url = data.forwardStart + '://' + data.dev_forward_url
        this.$delete(data, 'forwardStart')

        this.requestApi('/adm/add_external_apply', {data}).then((res) => {
          if (res.status === 1) {
            _this.alertSucc('添加成功')
            _this.cancelModal()
            _this.init()
          } else {
            _this.alertErr(res.msg)
          }
        })
      },
      // 上传图片成功
      uploadSuccess(res) {
        if (res.status === 1) {
          res.pic_url = res.pic_url.indexOf('/uploads') > -1 ? this.ImgUrl + res.pic_url : 'https://' + res.pic_url
          this.addModal.data.thumb = res.pic_url;
        } else {
          this.alertErr(res.msg)
        }
      },
      openDeviceModal (item) {
        const _this = this
        this.deviceModal.show = true
        this.deviceModal.item = item
        this.requestApi('/adm/get_package_money').then(res => {
          if (res.status) {
            _this.deviceModal.money = res.data
          }
        })
      },
      cancelDeviceModal () {
        this.deviceModal.show = false
        this.deviceModal.data = {
          number: 1
        }
      },
      // 添加最大设备数量
      confirmDeviceModal () {
        const _this = this
        let data = {
          apply_id: this.deviceModal.item.id,
          number: this.deviceModal.data.number
        }
        this.requestApi('/adm/create_external_apply_order',{ data }).then(res => {
          if (res.status === 1) {
            _this.cancelDeviceModal()
            _this.payModal.show = true
            _this.payModal.code_url = res.data.code_url
            _this.payModal.orderInterval = setInterval(() => {
              _this.requestApi('/adm/get_package_order_status', {
                order_number: res.data.order_number
              }).then(res => {
                  if (res.data === 1) {
                    _this.cancelPayModal()
                    _this.alertSucc('支付成功')
                    _this.init()
                  }
                }
              )
            }, 500)
          } else {
            _this.alertErr(res.msg)
          }
        })
      },
      cancelPayModal() {
        clearInterval(this.payModal.orderInterval)
        this.payModal.show = false
        this.payModal.code_url = ''
        this.payModal.orderInterval = null
      }
		}
	}
</script>

<style>
@import "../../assets/public/css/home.css";

.welcome .ivu-form-item {
  margin-bottom: 0 !important;
}
</style>
