import Home from "../views/home/Home"

export default [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta:{
            title:'总览',
            keepAlive:false
        }
    },
    {
        path: '/authorize',
        name: 'Authorize',
        component:()=>import('../views/home/authorize'),
        meta:{
            title: '授权',
            keepAlive: false
        }
    },
    {
        path: '/cloud',
        name: 'Cloud',
        component:()=>import('../views/home/cloud'),
        meta:{
            title: '云储存',
            keepAlive: false
        }
    },
    {
        path: '/device',
        name: 'Device',
        component:()=>import('../views/home/device'),
        meta:{
            title: '硬件设备',
            keepAlive: false
        }
    },
    {
        path: '/Interface',
        name: 'Interface',
        component:()=>import('../views/home/Interface'),
        meta:{
            title: '第三方接口',
            keepAlive: false
        }
    },
    {
        path: '/apply',
        name: 'Apply',
        component:()=>import('../views/home/apply'),
        meta:{
            title: '应用管理',
            keepAlive: false
        }
    },
]
