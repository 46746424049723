export default [{
		path: '/aipayment',
		name: 'AiPayment',
		component: () => import('../views/ai/aipayment'),
		meta: {
			title: '寻疗AI',
			keepAlive: false
		}
	},
	{
		path: '/aidtl',
		name: 'AiDtl',
		component: () => import('../views/ai/aidtl'),
		meta: {
			title: 'AI充值',
			keepAlive: false
		}
	},
	{
		path: '/chat_ai/order_list',
		name: 'ChatAiOrderList',
		component: () => import('../views/chat_ai/order_list'),
		meta: {
			title: '问答AI',
			keepAlive: false
		}
	},
]